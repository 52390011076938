import React from 'react'
import {Link} from 'react-router-dom'
import '../App.css';


export const Navbar = () => {
    return (
        <nav className='App'>
            <img src="https://institutomlf.files.wordpress.com/2019/12/mlf-2.png?w=768&h=207" alt="" height='100px'/>
            <ul>
                <li>
                <Link to='/'>Home</Link>
                </li>
                <li>
                <Link to='/contacto'>Contacto</Link>
                </li>
                <li>
                <Link to='/Disponibilidad'>Disponnibilidad</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar