import React from 'react'
import '../App.css';


export const Error = () => {
    return (
        <>
            <div>
                Error
            </div>
        </>
    )
}

export default Error