import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Default from './ReactDefault'
import Contacto from './Pages/Contacto'
import Disponibilidad from './Pages/Disponibilidad'
import Inicio from './Pages/Inicio'
import Error from './Pages/Error'
import Navbar from'./Elements/Navbar'

function App() {
  return (
    <Router>
      <Navbar/>
      <Switch>
        <Route exact path='/'>
        <Inicio/>
        </Route>
        <Route path='/disponibilidad'>
          <Disponibilidad/>
        </Route>
        <Route path='/contacto'>
          <Contacto/>
        </Route>
        <Route path='*'>
          <Error/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
