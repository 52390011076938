import React from 'react'
import '../Styles/Inicio.css';
import Imcontexto from '../Elements/Imcontexto'
import primero from '../Elements/Imcontexto'

const images = [
'https://institutomlf.files.wordpress.com/2019/12/a0e6aa_cb1011573cea4d32bc72cbe262ed2d0b_mv2.jpg?w=1024',
'https://institutomlf.files.wordpress.com/2019/12/post_cursos_sin.jpg']

const text = [
    'Brindamos clases a domicilio y de forma online.',
    'Nos enfocamos en el conocimiento y sobre todo en la comodidad para que nuestros alumnos aprendan de la mejor manera.'
]

export const Inicio = () => {
    return (
        <div>
            <Imcontexto img={images[0]} text={text[0]} />
            <div className='textocomun'>
                <h1>{primero.url}</h1>
                <p>El alumno es el que arma las clases en base a su necesidad:</p>
                <p > <strong>Mejorar la fluidez</strong>, utilizar el idioma para viajes al exterior, 
                Inglés para Negocios, Inglés Técnico, etc.</p>
                <p>Brindamos al alumno el material necesario con el fin de acelerar 
                el proceso de aprendizaje de forma dinámica.</p>
                <p>Nos enfocamos en el conocimiento y sobre todo en la comodidad para
                 que nuestros alumnos aprendan de la mejor manera.</p>
            </div>
            <Imcontexto img={images[1]} text={text[1]} />
            <div className='textocomun'>
                <h1>Las Clases</h1>
                <p>Ofrecemos clases individuales y grupales, presenciales (a domicilio) o 
                de forma online TOTALMENTE PERSONALIZADAS.</p>
            </div>
            <h2>¿Cuánto Cuesta?</h2>
            <div>Suscrpcionessssssss</div>
            <div>¿Dónde Estamossssssssssss?</div>
        </div>
        )
    }
    
    export default Inicio