import React from 'react'
import '../App.css';


export const Contacto = () => {
    return (
        <>
            <div>
                Contacto
            </div>
        </>
    )
}

export default Contacto