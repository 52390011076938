import React from 'react'
import '../App.css';


export const Disponibilidad = () => {
    return (
        <>
            <div>
                Disponibilidad
            </div>
        </>
    )
}

export default Disponibilidad