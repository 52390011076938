import React, { Component } from 'react';
import '../Styles/Imcontexto.css';

const images = [
'https://institutomlf.files.wordpress.com/2019/12/a0e6aa_cb1011573cea4d32bc72cbe262ed2d0b_mv2.jpg?w=1024',
'https://institutomlf.files.wordpress.com/2019/12/post_cursos_sin.jpg']

const Imcontexto = (props) => {
    return (
        <div className='conjunto'>
            <div className='imagen'>
                <div>
                    <img src={props.img} alt=""/>
                </div>
            </div>
            <div className='texto'>
                <div>
                    <h2>{props.text}</h2>
                </div>
            </div>
        </div>
    )
}

export default Imcontexto
